import React from 'react'
import Container from '../components/layout/container'
import Layout from '../components/layout/layout'
import Seo from '../components/seo/seo'
import Content from '../components/ui/content'
import PageHeader from '../components/ui/page-header'
import { StaticImage } from 'gatsby-plugin-image'

const Hakkimizda = () => {
  return (
    <Layout>
      <Seo title='Hakkımızda' description='test açıklama' article lang='tr' />
      <PageHeader title='Hakkımızda' />
      <Container className='py-10 text-center'>
        <h2 className='text-5xl text-white my-5'>Sayin Tekstil Hakkında</h2>
        <StaticImage src='../images/hr1.png' />
      </Container>
      <Container className='flex flex-wrap'>
        <div className='w-full md:w-1/2'>
          <div className='prose prose-sm md:prose-md font-light text-white md:pr-5'>
            <p>
              Geçmişi kurucusu Halil Sayın’ın 1960’lı yıllardaki şahıs şirketi
              olarak çalışmalarına dek uzanan firmamız 1994 yılında
              şirketleşerek SAYIN TEKSTİL SANAYİ VE TİCARET LİMİTED ŞİRKETİ
              adını almıştır.
            </p>
            <p>
              İlk yıllarında Denizli tekstil ticaretinin merkezi sayılan
              Bayramyeri’ndeki adresinde hambez, baskılı bez ve renkli bez
              ticareti yaparak faliyet göstermiştir. 1997 yılında ilk üretim
              yatırımını Denizli – Ankara karayolu üzerindeki 7.400m2 arsa
              üzerinde 4.800m2 kapalı alana sahip fabrikasında Haşıl ve Çözgü
              tesisi kurarak yapmıştır. 2002 yılından itibaren Sulzer marka
              dokuma tezgahları satın alarak aynı fabrikada dokuma tesisi
              kurmuştur. Yıllar içerisinde tezgah sayısını arttırarak 2014
              yılına kadar 56 adete ulaştırmıştır. 2015 yılında ise 10 Adet
              Itema marka tezgah daha üretim hattına katılmıştır. Günümüzde eni
              280cm ve 390cm arasında değişen 66 adet tezgah ile üretimine devam
              etmektedir.
            </p>
            <p>
              Sayın Tekstil Ltd. ev tekstili ve giyim sektörünün ihtiyaç duyduğu
              ham kumaşların yanısıra farklı amaçlar için kullanılabilen
              kumaşların da üretimini yapmaktadır. Ayrıca, AKFİL markası ile
              kendi hazırladığı renk ve desenlerde Akfil, Saten, Baskılı kumaş
              ve Pike koleksiyonuna sahiptir.
            </p>
          </div>
        </div>
        <div className='w-full md:w-1/2'>
          <StaticImage
            src='../images/DSC_0070-1.jpg'
            alt='Sayın Tekstil Binası'
          />
        </div>
      </Container>
    </Layout>
  )
}

export default Hakkimizda
